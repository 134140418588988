body {
  font-family: 'Poppins';
}

li {
  list-style-type: none;
}

.img-circular {
  object-fit: contain;
  background-color: white;
}

.primary-text {
  color: #90A0D9;
}

.secondary-text {
  color: #BDBDDD;
}

.tertiary-text {
  color: #CDCDFF;
}

.text-center {
  text-align: center;
}

.br-50 {
  border-radius: 50%;
}

.tally-block-heading-3 h3 {
  color: white !important; 
}

.shadow {
  transition: transform .2s linear,-webkit-transform .2s linear
}

.shadow:hover {
  /* transform: scale(1.01); */
  transform: translateY(-4px);
}

.floating-arrow {
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #BDBDDD;
  transition: text-decoration 0.3s linear;
}
a:visited {
  text-decoration: none;
}
a:hover, a::after {
  text-decoration: none;
  color: #90A0D9;
}

.hamburger {
  display: none;
}

.menu-option {
  display: block;
}


@media screen and (max-width: 600px) {
  .menu-option {
    display: none;
  }
  .hamburger {
    display: block;
    width: 24;
    height: 24;
    cursor: pointer;
  }
  .floating-arrow {
    display: none;
  }
  #about h1, #about h2 {
    text-align: left !important;
  }
}